var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wrapper',[_c('section',{staticClass:"account-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"box box-info"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',{staticClass:"box-title"},[_vm._v("Edit User")])]),_c('div',{staticClass:"box-body"},[(_vm.countriesLoaded && _vm.userLoaded)?_c('account-form',{attrs:{"countries":_vm.countries,"collections":_vm.availableCollections,"publishers":_vm.availablePublishers,"account-fields":{
                  first_name: true,
                  last_name: true,
                  password: true,
                  email: true,
                  job_role: _vm.user && _vm.user.user_type === 'enterprise',
                  country: true,
                  region: true,
                  permissions: true
                }},on:{"validated":_vm.updateAccount},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }